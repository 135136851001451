<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M17.1011 2.61997C15.4115 0.930342 13.1649 -9.45014e-05 10.7756 7.19903e-09C10.384 7.19903e-09 10.0668 0.31736 10.0668 0.708814C10.0668 1.10027 10.3841 1.41763 10.7756 1.41763C12.7863 1.41753 14.6768 2.20054 16.0987 3.62242C17.5207 5.0443 18.3037 6.93485 18.3036 8.94571C18.3036 9.33716 18.6208 9.65452 19.0124 9.65452C19.4038 9.65452 19.7212 9.33716 19.7212 8.9458C19.7213 6.55615 18.7909 4.30959 17.1011 2.61997Z"
        fill="white"/>
    <path
        d="M14.1854 8.9458C14.1854 9.33725 14.5027 9.65461 14.8943 9.65452C15.2857 9.65452 15.603 9.33716 15.603 8.9457C15.6028 6.28434 13.4372 4.11887 10.7757 4.11849C10.7756 4.11849 10.7757 4.11849 10.7756 4.11849C10.3841 4.11849 10.0669 4.43576 10.0668 4.82721C10.0668 5.21867 10.384 5.53603 10.7755 5.53612C12.6555 5.5364 14.1852 7.06593 14.1854 8.9458Z"
        fill="white"/>
    <path
        d="M12.6169 12.5598C11.5365 12.5039 10.9861 13.3074 10.7221 13.6934C10.501 14.0165 10.5838 14.4575 10.9069 14.6786C11.2301 14.8997 11.6711 14.8169 11.8922 14.4937C12.2041 14.0377 12.3454 13.966 12.537 13.9751C13.1505 14.0472 15.5666 15.8177 15.8085 16.3713C15.8692 16.5344 15.8669 16.6942 15.8018 16.8886C15.5482 17.6414 15.1283 18.1705 14.5873 18.4185C14.0734 18.6541 13.4433 18.6327 12.7656 18.357C10.2349 17.3255 8.02396 15.886 6.19428 14.0785C6.19352 14.0777 6.19276 14.0771 6.1921 14.0763C4.38831 12.2484 2.9515 10.0403 1.92173 7.51353C1.64586 6.83524 1.6245 6.20506 1.86011 5.69122C2.1081 5.15025 2.63716 4.73035 3.38936 4.47697C3.58442 4.41157 3.74395 4.40949 3.90547 4.4696C4.46108 4.71239 6.23151 7.12841 6.30296 7.73496C6.31317 7.93381 6.24087 8.07501 5.78524 8.38632C5.46202 8.60709 5.37895 9.04816 5.59982 9.37138C5.82059 9.6946 6.26157 9.77758 6.58488 9.55681C6.97104 9.29313 7.77437 8.74422 7.71861 7.66011C7.65718 6.52771 5.45437 3.52886 4.40183 3.14185C3.93373 2.96738 3.44134 2.96436 2.93779 3.13334C1.80483 3.51487 0.986571 4.19515 0.571489 5.10054C0.168883 5.9789 0.181641 6.99799 0.60882 8.04816C1.71088 10.7522 3.25279 13.1183 5.19164 15.0807C5.19636 15.0856 5.20118 15.0903 5.2061 15.0949C7.16715 17.0302 9.53043 18.5692 12.2309 19.67C12.7717 19.8899 13.3043 20 13.8136 20C14.2931 20 14.7522 19.9025 15.1781 19.7072C16.0836 19.2922 16.7637 18.4741 17.1456 17.3403C17.3142 16.8377 17.3114 16.3455 17.1383 15.8796C16.7499 14.8242 13.7511 12.6214 12.6169 12.5598Z"
        fill="white"/>
  </svg>

</template>

<script>
export default {
  name: "TelephoneIcon"
}
</script>

<style scoped>

</style>
