<template>
  <ion-menu side="end" menu-id="first" content-id="main" class="side-navigation">
    <ion-header class="side-navigation-header">
      <ion-toolbar class="side-navigation-header-toolbar">
        <ion-title>Menu</ion-title>
        <span class="close-icon">
          <CloseIcon/>
        </span>
      </ion-toolbar>
    </ion-header>
    <ion-content class="side-navigation-content-area">
      <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
        <ion-item @click="selectedIndex = i"
                  router-direction="root"
                  :router-link="p.url"
                  lines="none"
                  class="side-navigation-item"
                  :class="{ selected: selectedIndex === i }">
          <component v-bind:is="p.iconComponent"></component>
          <ion-label>{{ p.title }}</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-label class="version-label">
        App Version 1.042
      </ion-label>
    </ion-content>
  </ion-menu>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import {useRoute} from 'vue-router';
import DashboardIcon from '@/components/icons/DashboardIcon.vue'
import InfoIcon from '@/components/icons/InfoIcon.vue'
import SubscriptionIcon from '@/components/icons/SubscriptionIcon.vue'
import TelephoneIcon from '@/components/icons/TelephoneIcon.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'

export default defineComponent({
  name: 'App',
  components: {
    IonTitle, IonToolbar, IonHeader, IonContent, IonItem, IonMenuToggle, IonMenu, IonIcon, IonLabel, CloseIcon
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Main',
        url: '/',
        iconComponent: DashboardIcon
      },
      {
        title: 'About Us',
        url: '/about-us',
        iconComponent: InfoIcon
      },
      {
        title: 'Subscription',
        url: '/subscription',
        iconComponent: SubscriptionIcon
      },
      {
        title: 'Contact Us',
        url: '/contact-us',
        iconComponent: TelephoneIcon
      },
    ];


    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

    const route = useRoute();

    return {
      selectedIndex,
      appPages,
      isSelected: (url: string) => url === route.path ? 'selected' : ''
    }
  }
});
</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
