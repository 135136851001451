import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import Home from '../views/Home.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/tabs/main'
    },
    {
        path: '/tabs/',
        component: Home,
        children: [
            {
                path: '',
                redirect: '/tabs/main'
            },
            {
                path: 'main',
                component: () => import('@/views/tabs/Main.vue')
            },
            {
                path: 'search',
                component: () => import('@/views/tabs/Search.vue')
            },
            {
                path: 'favorites',
                component: () => import('@/views/tabs/Favorites.vue')
            },
            {
                path: 'settings',
                component: () => import('@/views/tabs/Settings.vue')
            }
        ]
    },
    {
        path: '/article-details',
        component: () => import('@/views/ArticleDetails.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
