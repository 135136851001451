<template>
  <ion-page>
    <NavBarIcon/>
    <BottomNavigation/>
  </ion-page>
</template>

<script lang="ts">
import {IonPage} from '@ionic/vue';
import NavBarIcon from "@/components/icons/NavBarIcon.vue";
import BottomNavigation from "@/layouts/BottomNavigation.vue";

export default {
  name: 'Home',
  components: {IonPage, BottomNavigation, NavBarIcon},
  setup() {
    return {}
  }
}
</script>
