<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M7.70828 6.66672H1.45828C0.654144 6.66672 0 6.01257 0 5.20828V1.45828C0 0.654144 0.654144 0 1.45828 0H7.70828C8.51257 0 9.16672 0.654144 9.16672 1.45828V5.20828C9.16672 6.01257 8.51257 6.66672 7.70828 6.66672ZM1.45828 1.25C1.34338 1.25 1.25 1.34338 1.25 1.45828V5.20828C1.25 5.32333 1.34338 5.41672 1.45828 5.41672H7.70828C7.82333 5.41672 7.91672 5.32333 7.91672 5.20828V1.45828C7.91672 1.34338 7.82333 1.25 7.70828 1.25H1.45828Z"
        fill="white"/>
    <path
        d="M7.70828 20H1.45828C0.654144 20 0 19.3459 0 18.5417V9.79172C0 8.98743 0.654144 8.33328 1.45828 8.33328H7.70828C8.51257 8.33328 9.16672 8.98743 9.16672 9.79172V18.5417C9.16672 19.3459 8.51257 20 7.70828 20ZM1.45828 9.58328C1.34338 9.58328 1.25 9.67667 1.25 9.79172V18.5417C1.25 18.6566 1.34338 18.75 1.45828 18.75H7.70828C7.82333 18.75 7.91672 18.6566 7.91672 18.5417V9.79172C7.91672 9.67667 7.82333 9.58328 7.70828 9.58328H1.45828Z"
        fill="white"/>
    <path
        d="M18.5417 20H12.2917C11.4874 20 10.8333 19.3459 10.8333 18.5417V14.7917C10.8333 13.9874 11.4874 13.3333 12.2917 13.3333H18.5417C19.3459 13.3333 20 13.9874 20 14.7917V18.5417C20 19.3459 19.3459 20 18.5417 20ZM12.2917 14.5833C12.1767 14.5833 12.0833 14.6767 12.0833 14.7917V18.5417C12.0833 18.6566 12.1767 18.75 12.2917 18.75H18.5417C18.6566 18.75 18.75 18.6566 18.75 18.5417V14.7917C18.75 14.6767 18.6566 14.5833 18.5417 14.5833H12.2917Z"
        fill="white"/>
    <path
        d="M18.5417 11.6667H12.2917C11.4874 11.6667 10.8333 11.0126 10.8333 10.2083V1.45828C10.8333 0.654144 11.4874 0 12.2917 0H18.5417C19.3459 0 20 0.654144 20 1.45828V10.2083C20 11.0126 19.3459 11.6667 18.5417 11.6667ZM12.2917 1.25C12.1767 1.25 12.0833 1.34338 12.0833 1.45828V10.2083C12.0833 10.3233 12.1767 10.4167 12.2917 10.4167H18.5417C18.6566 10.4167 18.75 10.3233 18.75 10.2083V1.45828C18.75 1.34338 18.6566 1.25 18.5417 1.25H12.2917Z"
        fill="white"/>
  </svg>
</template>
<script>
export default {
  name: "DashboardIcon"
}
</script>

<style scoped>

</style>
