<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="9.25" stroke="white" stroke-width="1.5"/>
    <path
        d="M9 15C8.70526 15 8.47368 14.9144 8.30526 14.7433C8.13684 14.5722 8.05263 14.3386 8.05263 14.0424C8.05263 13.7397 8.13333 13.3317 8.29474 12.8184C8.46316 12.2985 8.62807 11.8279 8.78947 11.4067C8.95789 10.9855 9.04211 10.7058 9.04211 10.5676C9.04211 10.4228 8.99298 10.3504 8.89474 10.3504C8.64912 10.3504 8.25263 10.8045 7.70526 11.7127L7 11.3277C8.03158 9.69562 8.97895 8.87957 9.84211 8.87957C10.4316 8.87957 10.7263 9.22836 10.7263 9.92596C10.7263 10.13 10.6561 10.4557 10.5158 10.9033L9.75789 12.9368C9.6807 13.1277 9.64211 13.3021 9.64211 13.46C9.64211 13.6114 9.70526 13.6871 9.83158 13.6871C10.0702 13.6871 10.5088 13.2725 11.1474 12.4432L11.5895 12.8282C11.2105 13.4863 10.7579 14.0128 10.2316 14.4077C9.71228 14.8026 9.30175 15 9 15ZM12 5.96742C12 6.23067 11.9053 6.47088 11.7158 6.68806C11.5263 6.90523 11.2702 7.01382 10.9474 7.01382C10.6246 7.01382 10.3825 6.92498 10.2211 6.74729C10.0596 6.56301 9.97895 6.33268 9.97895 6.05627C9.97895 5.77986 10.0772 5.53636 10.2737 5.32577C10.4772 5.10859 10.7368 5 11.0526 5C11.3684 5 11.6035 5.09543 11.7579 5.28628C11.9193 5.47055 12 5.6976 12 5.96742Z"
        fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "InfoIcon"
}
</script>

<style scoped>

</style>
