<template>
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 4.15503C20 3.0596 19.1014 2.16833 17.9971 2.16833C16.8927 2.16833 15.9941 3.0596 15.9941 4.15503C15.9941 4.78995 16.2964 5.3559 16.7653 5.71982L12.9866 8.43726L10.8386 4.64117C11.4981 4.3157 11.9525 3.6405 11.9525 2.86154C11.9525 1.76611 11.0541 0.875 9.94965 0.875C8.84521 0.875 7.94678 1.76611 7.94678 2.86154C7.94678 3.64996 8.41217 4.33249 9.08463 4.65323L7.10205 8.44153L3.22433 5.58447C3.69919 5.22086 4.00589 4.65125 4.00589 4.0116C4.00589 2.91617 3.1073 2.0249 2.00287 2.0249C0.89859 2.0249 0 2.91617 0 4.0116C0 4.94757 0.656281 5.73401 1.53625 5.94305L3.79105 14.5502V16.5478C3.79105 16.8714 4.05334 17.1337 4.37698 17.1337H15.7872C16.1107 17.1337 16.3731 16.8714 16.3731 16.5478V14.5463L18.4695 6.08527C19.3466 5.87393 20 5.08902 20 4.15503ZM17.9971 3.34021C18.4554 3.34021 18.8281 3.70566 18.8281 4.15488C18.8281 4.60425 18.4554 4.9697 17.9971 4.9697C17.5388 4.9697 17.166 4.6041 17.166 4.15488C17.166 3.70566 17.5388 3.34021 17.9971 3.34021ZM9.94965 2.04688C10.4079 2.04688 10.7806 2.41232 10.7806 2.86154C10.7806 3.30878 10.4111 3.6727 9.95575 3.67606C9.95407 3.6759 9.95239 3.6759 9.95071 3.6759C9.94766 3.6759 9.94446 3.6759 9.94141 3.6759C9.48685 3.67163 9.1185 3.30817 9.1185 2.86154C9.1185 2.41232 9.49127 2.04688 9.94965 2.04688ZM1.17188 4.0116C1.17188 3.56223 1.54465 3.19678 2.00287 3.19678C2.46124 3.19678 2.83401 3.56223 2.83401 4.0116C2.83401 4.46082 2.46124 4.82626 2.00287 4.82626C1.54465 4.82626 1.17188 4.46082 1.17188 4.0116ZM4.96292 15.9618V15.0611L15.2013 15.0608V15.9618H4.96292ZM15.3287 13.8889L4.82941 13.8892L2.98981 6.86713L6.95679 9.7901C7.09442 9.89157 7.26959 9.92789 7.43607 9.88943C7.60269 9.85098 7.74429 9.74158 7.82349 9.59006L9.97086 5.48712L12.2885 9.58289C12.3705 9.72769 12.5101 9.83084 12.6727 9.86655C12.8352 9.90225 13.0054 9.86716 13.1406 9.76996L17.045 6.96204L15.3287 13.8889Z" fill="white"/>
  </svg>

</template>

<script>
export default {
  name: "SubscriptionIcon"
}
</script>

<style scoped>

</style>
