
import {IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs} from '@ionic/vue';
import {heartOutline, homeOutline, searchOutline, settingsOutline} from "ionicons/icons";

export default {
  name: 'bottom-navigation',
  components: {IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon},
  setup() {
    return {
      homeOutline,
      searchOutline,
      heartOutline,
      settingsOutline
    }
  }
}
