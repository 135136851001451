
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import {useRoute} from 'vue-router';
import DashboardIcon from '@/components/icons/DashboardIcon.vue'
import InfoIcon from '@/components/icons/InfoIcon.vue'
import SubscriptionIcon from '@/components/icons/SubscriptionIcon.vue'
import TelephoneIcon from '@/components/icons/TelephoneIcon.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'

export default defineComponent({
  name: 'App',
  components: {
    IonTitle, IonToolbar, IonHeader, IonContent, IonItem, IonMenuToggle, IonMenu, IonIcon, IonLabel, CloseIcon
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Main',
        url: '/',
        iconComponent: DashboardIcon
      },
      {
        title: 'About Us',
        url: '/about-us',
        iconComponent: InfoIcon
      },
      {
        title: 'Subscription',
        url: '/subscription',
        iconComponent: SubscriptionIcon
      },
      {
        title: 'Contact Us',
        url: '/contact-us',
        iconComponent: TelephoneIcon
      },
    ];


    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

    const route = useRoute();

    return {
      selectedIndex,
      appPages,
      isSelected: (url: string) => url === route.path ? 'selected' : ''
    }
  }
});
