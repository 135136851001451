<template>
  <div class="nav-bar-icon" slot="end" @click="openEnd">
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 17H0V20H24V17Z" fill="#29304D"/>
      <path d="M24 8.33334H5.33337V11.6667H24V8.33334Z" fill="#29304D"/>
      <path d="M24 0H0V3.33335H24V0Z" fill="#29304D"/>
    </svg>
  </div>
</template>

<script lang="ts">

import {menuController} from '@ionic/vue';

export default {
  name: "nav-bar-icon",
  methods: {
    openEnd() {
      menuController.open('end');
    },
  }
}
</script>
