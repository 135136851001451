<template>
  <ion-tabs>
    <ion-tab-bar slot="bottom" class="bottom-navigation">
      <ion-tab-button tab="Main"
                      href="/tabs/main"
                      class="bottom-navigation-item">
        <ion-icon class="bottom-navigation-item-icon" :icon="homeOutline"/>
        <ion-label class="bottom-navigation-item-label">Main</ion-label>
      </ion-tab-button>
      <ion-tab-button tab="Search"
                      href="/tabs/search"
                      class="bottom-navigation-item">
        <ion-icon class="bottom-navigation-item-icon" :icon="searchOutline"/>
        <ion-label class="bottom-navigation-item-label">Search</ion-label>
      </ion-tab-button>
      <ion-tab-button tab="Favorites"
                      href="/tabs/favorites"
                      class="bottom-navigation-item">
        <ion-icon class="bottom-navigation-item-icon" :icon="heartOutline"/>
        <ion-label class="bottom-navigation-item-label">Favorites</ion-label>
      </ion-tab-button>
      <ion-tab-button tab="Settings"
                      href="/tabs/settings"
                      class="bottom-navigation-item">
        <ion-icon class="bottom-navigation-item-icon" :icon="settingsOutline"/>
        <ion-label class="bottom-navigation-item-label">Settings</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</template>

<script lang="ts">
import {IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs} from '@ionic/vue';
import {heartOutline, homeOutline, searchOutline, settingsOutline} from "ionicons/icons";

export default {
  name: 'bottom-navigation',
  components: {IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon},
  setup() {
    return {
      homeOutline,
      searchOutline,
      heartOutline,
      settingsOutline
    }
  }
}
</script>
