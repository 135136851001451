<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" @click="toggle">
    <g clip-path="url(#clip0)">
      <path
          d="M20 2.35676L17.6432 0L9.99997 7.64322L2.35676 0L0 2.35676L7.64322 9.99997L0 17.6432L2.35676 20L9.99997 12.3568L17.6432 20L19.9999 17.6432L12.3568 9.99997L20 2.35676Z"
          fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import {menuController} from '@ionic/vue'

export default {
  name: "CloseIcon",
  methods: {
    toggle: () => {
      menuController.toggle()
    }
  }
}
</script>

<style scoped>

</style>
