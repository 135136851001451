export default {
    categories: ['All', 'POLITIK', 'WIRTSCHAFT', 'TOURISMUS', 'SÜDTIROL', 'VIDEOS', 'KOLUMNE'],
    articles: [
        {
            image: require('@/assets/images/demo-image-1.jpg'),
            title: 'Mit Sanuslife macht Ewald Rieder ein gesundes Lebensgefühl zum',
            category: {
                id: 1,
                name: 'Wirtschaft'
            },
            readTime: '5 min Lesezeit',
            viewed: 35,
        },
        {
            image: require('@/assets/images/demo-image-2.jpg'),
            title: 'Wir brauchen einen neuen Feminismus',
            category: {
                id: 2,
                name: 'KOLUMNE'
            },
            readTime: '3 min Lesezeit',
            viewed: 97,
        },
        {
            image: require('@/assets/images/demo-image-3.jpg'),
            title: 'Thomas Oliver Müller erhält für Club-Deal Chicago einen Award',
            category: {
                id: 3,
                name: 'SÜDTIROL'
            },
            readTime: '2 min Lesezeit',
            viewed: 105,
        },
        {
            image: require('@/assets/images/demo-image-4.jpg'),
            title: 'Axel Reitz: Vom Neo-Nazi zum Präventions-Experten',
            category: {
                id: 4,
                name: 'POLITIK'
            },
            readTime: '4 min Lesezeit',
            viewed: 55,
        }
    ],
    latestArticles: [
        {title: 'Böhmermann, jetzt bist du dran', viewed: 2365},
        {title: 'Die Akzeptanz des öffentlich-rechtlichen Rundfunks schwindet', viewed: 2521},
        {title: 'Naivität, Sozialismus und Lilly Blaudszun', viewed: 2526},
        {title: 'Axel Reitz: Vom Neo-Nazi zum Präventions-Experten', viewed: 2655},
        {title: 'Jahresrückblick auf 2020', viewed: 1555},
    ]
}
