

import {menuController} from '@ionic/vue';

export default {
  name: "nav-bar-icon",
  methods: {
    openEnd() {
      menuController.open('end');
    },
  }
}
