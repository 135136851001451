export default {
    setColor({commit}, payload) {
        commit('setColor', payload)
        localStorage.setItem('color', payload)
    },
    setIfDark({commit}, payload) {
        commit('setIfDark', payload)
        localStorage.setItem('isDark', payload)
    },
    setLocale({commit}, payload) {
        commit('setLocale', payload)
        localStorage.setItem('locale', payload)
    }
}
